@font-face {
  font-family: 'SlowRiver';
  src: url('/public/fonts/SASlowRiver.woff') format('woff');
  /* Add additional font-face rules for different formats if necessary */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FCE7B3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.component, .contact, .pageComponent {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 20px auto;
}

.title {
  background-color: #FEA751;
  color: #fff;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  font-family: 'SlowRiver', sans-serif;
  letter-spacing: 2px;
}

.content {
  background-color: #F9EAD7;
  color: #000000;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
}

.copyright {
  text-align: center;
  padding-bottom: 10px;
}

.subtitle {
  margin-top: 8px;
}

a {
  color: inherit;
}

.eventDate {
  font-family: 'SlowRiver', sans-serif;
  background-color: #FFC995;
  text-align: center;
  color: #000;
  padding: 10px;
  margin: 10px 0;
  margin-top: 0;
  border-radius: 5px;
}

.webEventDetails{
  margin-bottom: 10px;
}

.eventDetails{
  margin-bottom: 5px;
}

.eventPhoto {
  max-width: inherit;
  border: 1px solid;
}

.eventWeb {
  font-size: x-small;
}

.announcement {
  background-color: #FEA751;
  color: white;
  font-family: 'SlowRiver', sans-serif;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: xx-large;
}

@media screen and (min-width: 501px) {
  .mobileEventDetails {
    display: none !important;
  }
  .webEventDetails {
    display: flex;
  }
  .eventDate {
    font-size: x-large;
  }
  .component, .pageComponent {
    max-height: 655px;
    overflow-y: auto;
  }
  .contact {
    max-height: 500px;
    overflow-y: auto;
  }

  .pageComponent {
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 500px) {
  .mobileEventDetails {
    display: block !important;
  }
  .webEventDetails {
    display: none;
  }
  .eventDate {
    font-size: inherit;
  }
  .table {
    font-size: 14px; /* Smaller font size for mobile responsiveness */
  }

  .buttonSystem {
    max-width: 100px !important;
  }
}

.mobileEventDetails {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.button {
  background-color: #FEA751; 
  border: none; 
  color: #461F22; 
  padding: 10px 20px; 
  text-align: center; 
  text-decoration: none; 
  display: inline-block; 
  font-size: 14px; 
  margin: 4px 2px; 
  cursor: pointer;
  border-radius: 8px;
  width: inherit;
}

.buttonContainer {
  display: flex;
  justify-content: center; 
  align-items: center;
  text-align: center;
}

.button:active {
  transform: translateY(4px); /* Pushes the button down a bit */
}

.subComponent {
  font-family: 'SlowRiver', sans-serif;
  background-color: #FFC995;
  text-align: center;
  color: #000;
  padding: 10px;
  margin: 10px 0;
  margin-top: 0;
  border-radius: 5px;
}

.navbar {
  font-family: 'SlowRiver', sans-serif;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: #F9EAD7; /* Light background to match the content area */
  color: #461F22; /* Dark text color for contrast */
  font-family: 'Roboto', sans-serif; /* Clean, readable font */
  margin: 20px 0; /* Spacing for visual separation */
  border-radius: 8px; /* Rounded corners to match other elements */
  overflow: hidden; /* Ensures the border-radius clips the table content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

table th, table td {
  padding: 12px 15px; /* Comfortable spacing within cells */
  text-align: left; /* Align text to the left for readability */
  border-bottom: 1px solid #ddd; /* Subtle line for separating rows */
}

table th {
  background-color: #FEA751; /* Header background to match the title bar */
  color: white; /* White text for contrast */
  font-family: 'SlowRiver', sans-serif; /* Consistent font with title */
}

table tr:last-child {
  border-bottom: none; /* Remove bottom border from the last row */
}

table tr:hover {
  background-color: #FFC995; /* Slight highlight on hover for interactivity */
}

.sliderImage {
  display: block; /* Removes default inline behavior */
  margin: 0 auto; /* Centers the image horizontally */
  max-width: 100%; /* Ensures the image is responsive and doesn't overflow its container */
  height: auto;
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center; /* This vertically centers the image if needed */
}

.banner {
  background-color: #f0f0f0; /* Light grey background */
  padding: 20px; /* Padding around the content */
  text-align: center; /* Center aligning the content */
  border: 1px solid #ddd; /* Border for the banner */
}

.banner h1 {
  color: #333; /* Dark grey color for the heading */
  margin: 0 0 10px 0; /* Margin for spacing */
}

.banner img {
  width: 100%; /* Full width of the container */
  height: auto; /* Auto height to maintain aspect ratio */
}

.eventTableRow {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.eventTableCell {
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
  text-align: center;
}

.clickableEvent {
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.clickableEvent:hover {
  background-color: #FCE7B3; /* Change background color on hover */
}

.modal-header {
  background-color: #FFC995;
  color: black;
  font-family: 'SlowRiver', sans-serif;
}

.square {
  display: flex;
  width: 100px;
  height: 100px;
  outline: auto;
}

.squareText {
  margin: auto;
  text-align: center;
}

.buttonSystem {
  max-width: 200px;
}